/* eslint-disable */
import { Options, Vue } from 'vue-class-component'
import { Action, Getter, Mutation, State } from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
const namespace: string = 'customer';
import * as messageModal from "@/store/message-modal.js"
import $ from "jquery";
import {toBase64} from "@/services/api.upload";

export default class UserAccountPage extends Vue {
	@State('customer') customer: CustomerState | undefined;
	@Action('fetchUserData', {namespace}) fetchUserData: any;
    @Getter('getUserData', {namespace}) userData: any;
    @Action('login', {namespace}) login: any;
    @Action('logout', {namespace}) logout: any;

	@Action('getUserSettings', {namespace}) getUserSettings: any;
    @Getter('userSettings', {namespace}) userSettings: any;
    @Action('updateUserSettings', {namespace}) updateUserSettings: any;

    @Action('changeNickName', {namespace}) changeNickname: any;
	@Action('changeEmail', {namespace}) changeEmail: any;
    @Action('changePassword', {namespace}) changePassword: any;
    @Action('resetPassword', {namespace}) resetPassword: any;
	@Action('changePhone', {namespace}) changePhone: any;
	@Action('changeAvatar', {namespace}) changeAvatar: any;

	@Mutation('setIsRecoveryPassword', {namespace}) setIsRecoveryPassword: any;
	@Mutation('saveToken', {namespace}) saveToken: any;
	@Getter('getRequestStatus', {namespace}) getRequestStatus: any;
	@Getter('getIsRecoveryPassword', {namespace}) getIsRecoveryPassword: any;

    timeVisual: number = 0;
	days: any = '--';
	hours: any = '--';
	minutes: any = '--';

	slidesSpeed: any = 250;

	token: any = false

    //Костыль
    userDataBugFix : any = {
        tariff : {
            time_left : 0
        },
        avatar : "",
        login : ""
    };

    //Аватар
    avatar : any = "";
    photoValue : any = "";
    $refs!: {
        refProfilePhoto: HTMLInputElement
    };
    async handleFileUpload() {
        var _this = this;

        const photo = this.$refs.refProfilePhoto.files![0];
        console.log('Avatar toBase64', photo);
        this.photoValue = this.$refs.refProfilePhoto.value;
        return await toBase64(photo).then(function (data)
        {
            _this.changeAvatar({avatar : data}).then(function (response : any) {
                if (response.status == true) {
                    _this.fetchUserData().then(function () {
                        _this.avatar = _this.userData.avatar ? "background-image: url('"+ _this.userData.avatar + "')" : 'background-image: url("/images/user-account-avatar.png")';
                    });
                } else {
                    _this.Error(response.errors);
                }
            });
        });
    }

	TariffTimer()
	{
		if (this.timeVisual <= 0)
			return;

        this.timeVisual--;

        var date = new Date(0);
        date.setSeconds(this.timeVisual); // specify value for SECONDS here

        this.days = date.getDay();
        this.hours = date.getHours();
        this.minutes = date.getMinutes();

        if (this.days < 10) {this.days = "0" + this.days;}
        if (this.hours < 10) {this.hours = "0" + this.hours;}
        if (this.minutes < 10) {this.minutes = "0" + this.minutes;}
	}

	OpenSetting(e : any)
	{
        var settings = $(e.target).parents('.js-wrap').find('.js-setting');
        $('.js-setting').not(settings).hide();

        if (settings.is(':visible'))
            settings.slideUp(this.slidesSpeed);
        else
            settings.slideDown(this.slidesSpeed);
	}

	CloseSetting(e : any)
	{
        $(e.target).parents('.js-setting').slideUp(this.slidesSpeed);
	}

	SaveDone(e : any, on : any = undefined)
	{
		var _this = this;

		var setting = $(e.target).parents('.js-wrap').find('.js-setting');
        var done    = $(e.target).parents('.js-wrap').find('.js-done');
		if (on == 1)
            done = $(e.target).parents('.js-wrap').find('.js-done.js-on');
        else if (on == 0)
            done = $(e.target).parents('.js-wrap').find('.js-done.js-off');

        setting.slideUp(this.slidesSpeed);
        done.slideDown(this.slidesSpeed);

        setTimeout(function () {
            done.slideUp(_this.slidesSpeed);
        }, 1500);
	}

	ChangeInput(e : any)
	{
        e.target.classList.remove('error');
	}

	SwitchPasswordVisible(e : any)
    {
        if ($(e.target).siblings('input').attr('type') == "password")
        {
            $(e.target).css('background-image', 'url("/images/eye.png")');
            $(e.target).siblings('input').attr('type', 'text');
        }
        else
        {
            $(e.target).css('background-image', 'url("/images/eye-close.png")');
            $(e.target).siblings('input').attr('type', 'password');
        }
    }

	Error(errors : any)
	{
        if (errors.length)
            messageModal.Show("Ошибка", errors[0]);
	}

	//Никнейм
	ChangeNickname(e : any)
	{
		var _this = this;

		var newNickname = $('.js-nickname').val();
		if (!newNickname) {
            $('.js-nickname').addClass('error');
            return;
		}

        this.changeNickname({nickname : newNickname}).then(function(response : any) {
            if (response.status == true) {
                _this.fetchUserData();
                _this.SaveDone(e);
			} else {
				_this.Error(response.errors);
			}
        });
	}

	//Password
    ChangePassword(e : any)
    {
        var _this = this;
        var errors = 0;

        var wrap = $('.js-change-password');
        var oldPass : any = (!this.token) ? wrap.find('.js-pass-old') : "";
        var newPass : any = wrap.find('.js-pass-new');
        var newPassRepeat : any = wrap.find('.js-pass-new-repeat');

        if (oldPass && !oldPass.val()) {
            oldPass.addClass('error');
            errors++;
        }

        if (!newPass.val()) {
            newPass.addClass('error');
            errors++;
        }

        if (!newPassRepeat.val()) {
            newPassRepeat.addClass('error');
            errors++;
        }

        if (newPassRepeat.val() != newPass.val()) {
            newPassRepeat.addClass('error');
            errors++;
        }

        if (errors)
            return;


        if (this.token)
        {
            console.log("Token exist...");
            this.resetPassword({ email : this.userData.email, newPassword : newPass.val() }).then(function(response : any) {
                if (response.status == true) {
                    _this.fetchUserData();
                    _this.SaveDone(e);

                    _this.login({
                        login: _this.userData.login,
                        password: newPass.val(),
                    });
                } else {
                    _this.Error(response.errors);
                }
            });
        }
        else
        {
            this.changePassword({ oldPassword : oldPass.val(), newPassword : newPass.val() }).then(function(response : any) {
                if (response.status == true) {
                    _this.fetchUserData();
                    _this.SaveDone(e);
                } else {
                    _this.Error(response.errors);
                }
            });
        }
    }

	//Email
    ChangeEmail(e : any)
    {
        var _this = this;
        var errors = 0;

        var wrap = $('.js-change-email');
        var newEmail = wrap.find('.js-email').val();
        var pass = wrap.find('.js-pass').val();

        if (!newEmail) {
            $('.js-email').addClass('error');
            errors++;
        }

        if (!pass) {
            wrap.find('.js-pass').addClass('error');
            errors++;
        }

        if (errors)
        	return;

        this.changeEmail({email : newEmail, password : pass}).then(function(response : any) {
            if (response.status == true) {
                _this.fetchUserData();
                _this.SaveDone(e);
            } else {
                _this.Error(response.errors);
            }
        });
    }

    //SMS notification
    ChangeSmsNotification(e : any)
    {
        var _this = this;

        var phone = $('.js-phone').val();
        if (!phone) {
            $('.js-phone').addClass('error');
            return;
        }

        this.changePhone({phone : phone}).then(function(response : any) {
            if (response.status == true) {
                //_this.fetchUserData();
                //_this.SaveDone(e);
            } else {
                _this.Error(response.errors);
            }
        }).then(function () {
            var value = _this.userSettings.phone_notification ? 0 : 1;

            _this.updateUserSettings({phone_notification : value}).then(function (r2 : any) {
                if (r2.status == true) {
                    _this.fetchUserData();
                    _this.getUserSettings();
                    _this.SaveDone(e, value);
                } else {
                    _this.Error(r2.errors);
                }
            });
        });
    }

    //Email notification
    ChangeEmailNotification(e : any)
    {
        var _this = this;

        var value = _this.userSettings.email_notification ? 0 : 1;
        this.updateUserSettings({email_notification : value}).then(function (response : any) {
            if (response.status == true) {
                _this.getUserSettings();
                _this.SaveDone(e, value);
            } else {
                _this.Error(response.errors);
            }
        });
    }

    //Auto Refill
    ChangeAutoRefill(e : any)
    {
        var _this = this;

        var value = _this.userSettings.auto_replenishment ? 0 : 1;
        this.updateUserSettings({auto_replenishment : value}).then(function (response : any) {
            if (response.status == true) {
                _this.getUserSettings();
                _this.SaveDone(e, value);
            } else {
                _this.Error(response.errors);
            }
        });
    }

    //Select payment
    SelectPayment(selector : any)
    {
        $(selector).slideDown(this.slidesSpeed);
        $('.js-payment').not(selector).slideUp(this.slidesSpeed);
    }

	mounted()
	{
		var _this = this;

        messageModal.Init();

        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('token'))
        {
            this.token = true;
            $('.js-change-password .line').click();
        }

        this.getUserSettings().then(function () {
            console.log(_this.userSettings);
        });

        _this.fetchUserData().then(function () {
            _this.userDataBugFix = _this.userData;

            _this.timeVisual = _this.userData.tariff.time_left;
            _this.avatar = _this.userData.avatar ? "background-image: url('"+ _this.userData.avatar + "')" : 'background-image: url("/images/user-account-avatar.png")';
        });

		setInterval(this.TariffTimer, 1000);
	}
}
